import "./App.css";
import { Routes, Route } from "react-router-dom";
import Homepage from "./Homepage/homepage";
import Orders from "./Orders/orders";
import Products from "./Products/products";
import Product from "./Products/singleProduct";
import Locations from "./Pages/locations";
import Checkout from "./Pages/checkout";
import AboutUs from "./Pages/aboutUs";
// import Footer from "./components/footer";
// import OffcanvasExample from "./components/navbar";
import Profile from "./Pages/profile";
import ScrollToTop from "./utils/scrollToTop";
import ThankYou from "./Pages/thankYou";
import FAQs from "./components/faqs";
import FetchAllProductsData from "./API/fetchProductData";
import { CartProvider } from "./Cart/cartComponent";
// import ProductCategory from "./Products/productCategory";
import ProductCategoryV2 from "./Products/productCategoryV2";
import MainLayout from "./layout/MainLayout";
import RaiffeisenMenu from "./Pages/RaiffeisenMenu";
import MenuRBKO from "./Pages/MenuRBKO";

export default function App() {
  return (
    <>
      <ScrollToTop />
      <CartProvider>
        {/* <OffcanvasExample /> */}
        <Routes>
          <Route
            path="/"
            element={
              <MainLayout>
                <Homepage />
              </MainLayout>
            }
          />
          <Route
            path="/orders"
            element={
              <MainLayout>
                <Orders />
              </MainLayout>
            }
          />
          <Route
            path="/products"
            element={
              <MainLayout>
                <Products />
              </MainLayout>
            }
          />
          <Route
            path="/product/:slug"
            element={
              <MainLayout>
                <ProductCategoryV2 />
              </MainLayout>
            }
          />
          <Route
            path="/fetchAllProductsData"
            element={
              <MainLayout>
                <FetchAllProductsData />
              </MainLayout>
            }
          />
          <Route
            path="/locations"
            element={
              <MainLayout>
                <Locations />
              </MainLayout>
            }
          />
          <Route
            path="/products/:slug"
            element={
              <MainLayout>
                <Product />
              </MainLayout>
            }
          />
          <Route
            path="/checkout"
            element={
              <MainLayout>
                <Checkout />
              </MainLayout>
            }
          />
          <Route
            path="/about-us"
            element={
              <MainLayout>
                <AboutUs />
              </MainLayout>
            }
          />
          <Route
            path="/profile"
            element={
              <MainLayout>
                <Profile />
              </MainLayout>
            }
          />
          <Route path="/checkout/order-received" element={<ThankYou />} />
          <Route
            path="/faqs"
            element={
              <MainLayout>
                <FAQs />
              </MainLayout>
            }
          />
          <Route path="/raiffeisen/1127" element={<RaiffeisenMenu />} />
          <Route path="/raiffeisen/menurbko" element={<MenuRBKO />} />
          {/* <Route
            path="/raiffeisen/1127"
            element={() => {
              console.log("TEST");
              window.location.href = "/assets/FL_20240910_raiffeisenMenu_A4_compressed.pdf";
              return null;
            }}
          /> */}
        </Routes>
      </CartProvider>
      {/* <Footer /> */}
    </>
  );
}
